import {
   Observable,
   ReplaySubject,
   Subscription,
   from,
   of,
   Subject,
   BehaviorSubject,
   zip,
} from "rxjs";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
   IQueue_logQuery,
   IQueueLogResult,
   EQueryOperacion,
   ECallDisposicion,
   EModoAgenteQuery,
   EQueueDisposicion,
   EQueryType,
} from "../../_interfaces/informes";
import { PaginationInstance } from "ngx-pagination";
import * as _ from "lodash";
import * as moment from "moment";

import { HeaderService } from "../../_services/header.service";
import { SocketService, EComando } from "../../_services/socket.service";
import { QueueService } from "../../_services/queue.service";
import {
   map,
   toArray,
   tap,
   finalize,
   groupBy,
   mergeMap,
   switchMap,
   flatMap,
   filter,
   concatMap,
   catchError,
   delay,
} from "rxjs/operators";
import { FileHandlerService } from "../../_services/file.service";
import * as dayjs from "dayjs";
import { AgenteService } from "src/app/_services/agente.service";
import { Agente } from "src/app/_interfaces/_all";
import { UsuarioService } from "src/app/_services/usuario.service";
import { EUsuarioRol } from "src/app/_interfaces/usuario";

@Component({
   selector: "queue_log",
   templateUrl: "queue_log.component.html",
   styleUrls: ["queue_log.component.css"],
})
export class QueueLogComponent implements OnInit {
   actionDescarga = new Subject();
   llamadas: IQueueLogResult[];
   llamadas$ = new BehaviorSubject<Object[]>([]);
   agentes$ = new Observable<Agente[]>();
   estadisticasDeLlamada$: Observable<Object>;
   llamada: IQueueLogResult;
   private http_: Subscription;

   private campo = "";
   _q = EQueryType;
   m = EModoAgenteQuery;
   o = EQueryOperacion;
   d = ECallDisposicion;
   q = EQueueDisposicion;
   autosearch_colas$: Observable<string[]>;
   queryParams: {
      fuente?: string;
      destino?: string;
      modoAgente?: string;
      cola?: string;
   } = {
      fuente: this.o.EXACTO.toString(),
      destino: this.o.EXACTO.toString(),
      modoAgente: this.m.TODAS.toString(),
      cola: "",
   };

   public config: PaginationInstance = {
      id: "custom",
      itemsPerPage: 10,
      currentPage: 1,
   };

   get usuario() {
      return {
         permisos: this.$usr.usuario.permisos,
         colas: this.$usr.usuario.manager.map((m) => m.cola),
      };
   }
   get isPrivilged() {
      return this.$usr.usuario.permisos <= EUsuarioRol.MANAGER;
   }

   constructor(
      private $http: HttpClient,
      private $usr: UsuarioService,
      private $header: HeaderService,
      private $monitor: SocketService,
      private $queue: QueueService,
      private $file: FileHandlerService,
      private $agente: AgenteService
   ) {
      const calculadorStats$ = (
         inData$: Observable<{ evento: string; data: Object[] }>
      ) =>
         inData$.pipe(
            map((registros) => {
               if (registros.evento.includes("COMPLETE")) {
                  const registro = [...registros.data] as {
                     data2: string;
                     data1: string;
                  }[];
                  const totalConv = registro.reduce((acc, curr) => {
                     return acc + Number.parseInt(curr.data2 || "0");
                  }, 0);
                  const totalEspera = registro.reduce(
                     (acc, curr) => acc + Number.parseInt(curr.data1 || "0"),
                     0
                  );

                  return {
                     evento: registros.evento,
                     cantidad: registro.length,
                     totalConversacion: totalConv,
                     promedio: totalConv / registro.length,
                     promedioEspera: totalEspera / registro.length,
                  };
               } else if (registros.evento === "ABANDON") {
                  const registro = [...registros.data] as { data3: string }[];
                  const totalEspera = registro.reduce(
                     (acc, curr) => acc + Number.parseInt(curr.data3),
                     0
                  );
                  return {
                     evento: registros.evento,
                     cantidad: registro.length,
                     promedioEspera: totalEspera / registro.length,
                  };
               } else if (registros.evento === "RINGNOANSWER") {
                  return {
                     evento: registros.evento,
                     cantidad: registros.data.length,
                  };
               } else if (registros.evento.includes("TRANSFER")) {
                  return {
                     evento: registros.evento,
                     cantidad: registros.data.length,
                  };
               }
            }),
            filter((data) => !!data),
            toArray()
         );

      this.agentes$ = this.$agente.getAgentes();

      this.estadisticasDeLlamada$ = this.llamadas$.asObservable().pipe(
         filter((data) => data.length > 0),
         flatMap((data) =>
            from(data).pipe(
               map((llamada: { data: { data: { event: string }[] }[] }) => {
                  return (
                     llamada.data
                        .map((d) => d.data)
                        // Si hay multiples llamadas acumularlos.
                        .reduce((acc, curr) => [...acc, curr], [])
                        // Tomar el resultado de la reducción
                        .shift()
                  );
               }),
               concatMap((llamadas: { event: string }[]) => from(llamadas)),
               groupBy((evento) => evento.event),
               mergeMap((agrupado) =>
                  zip(of(agrupado.key), agrupado.pipe(toArray()))
               ),
               map(([evento, grupo]) => ({ evento: evento, data: grupo })),
               calculadorStats$
            )
         )
         // tap(data => console.log('stats', data))
      );
   }

   ngOnInit() {
      this.autosearch_colas$ = this.$queue.getQueues().pipe(
         map((queues) => queues.map((q) => q.nombre)),
         mergeMap((queuesAvailable) => {
            if (this.$usr.usuario.permisos > 1) {
               const allowedQueues = this.$usr.usuario.manager.map(
                  (m) => m.cola
               );
               this.queryParams = {
                  ...this.queryParams,
                  cola: allowedQueues.length > 0 ? allowedQueues[0] : "-",
               };
               return of(allowedQueues);
            } else {
               return of(queuesAvailable);
            }
         })
      );
   }

   containsEventComplete(evento: string) {
      return evento.includes("COMPLETE");
   }

   containsEventTransfer(evento: string) {
      return evento.includes("TRANSFER");
   }

   ejecutarBusquedaLog(query: IQueue_logQuery) {
      $("#modalWait_kerberus").modal();

      of(query)
         .pipe(
            switchMap((body) =>
               this.$http.post(
                  this.$header.getAPIurl() + "/queue_log.v2",
                  body,
                  { headers: this.$header.getHeaders(), responseType: "text" }
               )
            ),
            map(
               (res) => JSON.parse(`[${res.substring(1)}]`) as IQueueLogResult[]
            ),
            // revertir el orden
            map((resultados) => resultados.reverse()),
            // Preparar registros
            tap((resultados) => this.llamadas$.next(resultados)),
            finalize(() => $("#modalWait_kerberus").modal("hide"))
         )
         .subscribe(
            (resultados) => {
               this.llamadas = resultados;
            },
            (err) => console.error(err)
         );
   }

   /**
    * @deprecated
    * @param _query
    * @param qType
    */
   ejecutarBusqueda(_query: IQueue_logQuery, qType: EQueryType) {
      $("#modalWait_kerberus").modal();
      const query = _.cloneDeep(_query);
      query.queryType = qType;

      if (query.fuente.valor === "") {
         delete query.fuente;
      }
      if (query.destino.valor === "") {
         delete query.destino;
      }

      /* of(query).pipe(
            switchMap(body => this.$http.post(this.$header.getAPIurl() + '/queue_log', body, { headers: this.$header.getHeaders() })),
            tap(d => console.log(d)),
            flatMap(res => JSON.parse(`[${res.text().substring(1)}]`)),
            groupBy((registro: IQueueLogResult) => registro.callid),
            mergeMap(grupo => grupo.pipe(toArray())),
            tap(() => {
                oddCounter++;
            }), // por cada grupo
            flatMap(grupo => grupo),
            map(curr => {
                const registro = Object.assign({}, curr);
                if (oddCounter % 2 === 0) {
                    return Object.assign(registro, {linkedid: 'label-info', id: (oddCounter + 1)});
                } else {
                    return Object.assign(registro, {linkedid: 'label-warning', id: (oddCounter + 1)});
                }
            }),
            toArray(),
            finalize(() => ($('#modalWait_kerberus').modal('hide')))
        )
            .subscribe(
                resultados => {
                    if (qType === EQueryType.LIST) {
                        this.campo = '';
                        resultados = _.sortBy(<IQueueLogResult[]>resultados, ['calldate']).reverse();
                        this.llamadas = <IQueueLogResult[]>resultados;
                    } else {
                        // const _res = <{ filename: string }>resultados;
                        // window.open(_res.filename);
                    }
                },
                err => console.log(err)
            )*/
   }

   sortBy(campo: string) {
      if (this.campo !== campo) {
         this.llamadas = _.cloneDeep(_.sortBy(this.llamadas, [campo]));
      } else {
         this.llamadas = _.cloneDeep(this.llamadas.reverse());
      }
      this.campo = campo;
   }

   escucharLlamada(llamada: { data5: string; agent: string; callid: string }) {
      of(llamada.data5)
         .pipe(
            // Si id != "" seguir, sino, consultar
            tap(() =>
               $("#modalWait_kerberus").modal({
                  backdrop: "static",
                  keyboard: false,
               })
            ),
            mergeMap((idLlamada) =>
               !!idLlamada
                  ? of(idLlamada)
                  : of({ callid: llamada.callid, agent: llamada.agent }).pipe(
                       switchMap((body) =>
                          this.$http.post<{ callRecordFileName: string }>(
                             this.$header.getAPIurl() +
                                "/queue_log.v2/callRecord",
                             body,
                             { headers: this.$header.getHeaders() }
                          )
                       ),
                       map((response) => response.callRecordFileName)
                    )
            ),
            switchMap((idLlamada) =>
               this.$http.get(
                  this.$header.getAPIurl() + "/audio/" + idLlamada,
                  { headers: this.$header.getHeaders() }
               )
            ),
            map((response: { src: string }) => response.src),
            // Asignar datos para visualización
            tap((url) => {
               this.llamada = {
                  ...this.llamada,
                  src: "",
                  dst: llamada.agent,
                  audio: url,
               };
            }),
            catchError((err) => of(err)),
            tap(() => $("#modalWait_kerberus").modal("hide")),
            delay(900)
         )
         .subscribe(
            () => {
               $("#modalAudioQueue").modal({
                  backdrop: "static",
                  keyboard: false,
               });
            },
            (err) => {
               $("#modalWait_kerberus").modal("hide");
               console.error(err);
            }
         );
   }

   /**
    * @deprecated
    * @param llamada
    */
   escuchar(llamada: IQueueLogResult) {
      this.llamada = llamada;
      const audioSrc = llamada.userfield.split(";");
      let idLlamada = "";

      if (audioSrc.length > 0) {
         while (true) {
            idLlamada = audioSrc.shift();
            if (
               (!!idLlamada && idLlamada.length > 0) ||
               audioSrc.length === 0
            ) {
               break;
            }
         }
      } else {
         idLlamada = llamada.userfield;
      }

      if (!!this.http_ && !this.http_.closed) {
         this.http_.unsubscribe();
      }

      $("#modalWait_kerberus").modal({
         backdrop: "static",
         keyboard: false,
      });
      this.http_ = this.$http
         .get(this.$header.getAPIurl() + "/audio/" + idLlamada, {
            headers: this.$header.getHeaders(),
         })
         .pipe(
            flatMap((src: { src: string }) => {
               if (src.src.indexOf(".gsm") > -1) {
                  return this.$monitor
                     .enviarComando({
                        comando: EComando.AUDIO_XFORM,
                        data: {
                           in: src.src,
                           out: src.src.replace(".gsm", ".mp3"),
                        },
                     })
                     .pipe(map(() => src.src.replace(".gsm", ".mp3")));
               } else {
                  const obs = new ReplaySubject<string>(1);
                  obs.next(src.src);
                  return obs.asObservable();
               }
            })
         )
         .subscribe(
            (src: string) => {
               $("#modalWait_kerberus").modal("hide");
               this.llamada.audio = src;
               setTimeout(() => {
                  $("#modalAudioQueue").modal({
                     backdrop: "static",
                     keyboard: false,
                  });
               }, 1000);
            },
            (err) => {
               $("#modalWait_kerberus").modal("hide");
               console.log(err);
            }
         );
   }

   descargarReporte(agentes?: Agente[]) {
      from(this.llamadas$.value)
         .pipe(
            tap(() => $("#modalWait_kerberus").modal()),
            flatMap(
               (llamada: {
                  masterid: string;
                  data: {
                     callid: string;
                     data: {
                        callid: string;
                        event: string;
                        time: string;
                        queuename: string;
                        agent?: string;
                        data1?: string;
                        data2?: string;
                        data3?: string;
                        data4?: string;
                        data5?: string;
                     }[];
                  }[];
               }) =>
                  from(llamada.data).pipe(
                     mergeMap((evento) => {
                        const queueConnect = evento.data.find(
                           (e) => e.event === "QUEUE_CONNECT"
                        );
                        const resto = [
                           ...evento.data.filter(
                              (e) => e.event !== "QUEUE_CONNECT"
                           ),
                        ];
                        const fechaHelper = dayjs()
                           .set("hour", 0)
                           .set("minute", 0)
                           .set("second", 0)
                           .set("millisecond", 0);

                        const calcEsperaPorEvento = (registro: {
                           event: string;
                           data1?: string;
                           data2?: string;
                           data3?: string;
                        }) => {
                           switch (registro.event) {
                              case "ABANDON":
                              case "ATTENDEDTRANSFER":
                              case "BLINDTRANSFER":
                              case "TRANSFER":
                                 return fechaHelper
                                    .add(
                                       Number.parseInt(registro.data3),
                                       "second"
                                    )
                                    .format("HH:mm:ss");

                              case "COMPLETECALLER":
                              case "COMPLETEAGENT":
                              case "RINGNOANSWER":
                              case "NOANSWER":
                              case "BUSY":
                                 return fechaHelper
                                    .add(
                                       Number.parseInt(registro.data1),
                                       "second"
                                    )
                                    .format("HH:mm:ss");

                              default:
                                 return "00:00:00";
                           }
                        };

                        const calcDuracionPorEvento = (registro: {
                           event: string;
                           data1?: string;
                           data2?: string;
                           data3?: string;
                           data4?: string;
                        }) => {
                           switch (registro.event) {
                              case "COMPLETECALLER":
                              case "COMPLETEAGENT":
                                 return fechaHelper
                                    .add(
                                       Number.parseInt(registro.data2),
                                       "second"
                                    )
                                    .format("HH:mm:ss");

                              case "ATTENDEDTRANSFER":
                              case "BLINDTRANSFER":
                              case "TRANSFER":
                                 return fechaHelper
                                    .add(
                                       Number.parseInt(registro.data4),
                                       "second"
                                    )
                                    .format("HH:mm:ss");
                              default:
                                 return "00:00:00";
                           }
                        };

                        const calcularHoraAtencion = (registro: {
                           event: string;
                           time?: string;
                           data1?: string;
                           data2?: string;
                           data4?: string;
                        }) => {
                           switch (registro.event) {
                              case "COMPLETECALLER":
                              case "COMPLETEAGENT":
                                 const restarComplete =
                                    -1 * Number.parseInt(registro.data2);
                                 return dayjs(registro.time).add(
                                    restarComplete,
                                    "second"
                                 );

                              case "ATTENDEDTRANSFER":
                              case "BLINDTRANSFER":
                              case "TRANSFER":
                                 const restarXfer =
                                    -1 * Number.parseInt(registro.data4);
                                 return dayjs(registro.time).add(
                                    restarXfer,
                                    "second"
                                 );
                              default:
                                 return dayjs(registro.time);
                           }
                        };

                        const agenteSerialize = (idagente: string) => {
                           if (!!agentes && agentes.length > 0) {
                              const agente = agentes.find(
                                 (a) => a.idagente === idagente
                              );
                              if (!!agente) {
                                 return agente.nombre || "-";
                              }
                           }
                           return "-";
                        };

                        const registroExportar = resto.map(
                           (registro: {
                              callid: string;
                              event: string;
                              time?: string;
                              queuename?: string;
                              agent?: string;
                              data1?: string;
                              data2?: string;
                              data3?: string;
                              data4?: string;
                              data5?: string;
                           }) => {
                              return {
                                 "TIPO DE LLAMADA": queueConnect.data5,
                                 MASTERID: llamada.masterid,
                                 CALLID: queueConnect.callid,
                                 "FECHA INGRESO": dayjs(
                                    queueConnect.time
                                 ).format("YYYY-MM-DD"),
                                 "HORA INGRESO": dayjs(
                                    queueConnect.time
                                 ).format("HH:mm:ss"),
                                 "FECHA EVENTO": dayjs(registro.time).format(
                                    "YYYY-MM-DD"
                                 ),
                                 "HORA EVENTO":
                                    calcularHoraAtencion(registro).format(
                                       "HH:mm:ss"
                                    ),
                                 FUENTE: queueConnect.data2,
                                 DESTINO: queueConnect.data3,
                                 COLA: registro.queuename,
                                 "ID AGENTE": !registro.event.includes(
                                    "ABANDON"
                                 )
                                    ? registro.agent
                                    : "",
                                 "NOMBRE DE AGENTE": !registro.event.includes(
                                    "ABANDON"
                                 )
                                    ? agenteSerialize(registro.agent)
                                    : "-",
                                 EVENTO: registro.event,
                                 DURACION: calcDuracionPorEvento(registro),
                                 "TIEMPO DE ESPERA":
                                    calcEsperaPorEvento(registro),
                              };
                           }
                        );

                        return from(registroExportar);
                     })
                  )
            ),
            toArray(),
            tap((data) =>
               this.$file.exportCSVFile(
                  Object.keys(data[0]),
                  data,
                  "CallCenterLog_" + new Date().getTime()
               )
            ),
            finalize(() => $("#modalWait_kerberus").modal("hide"))
         )
         .subscribe();
   }

   csv() {
      $("#modalWait_kerberus").modal();

      const toCSV: {
         Fecha: string;
         Hora: string;
         Fuente: string;
         Cola: string;
         Agente: string;
         Nombre_Agente: string;
         Evento: string;
         Tiempo_Llamada: string;
         Tiempo_Espera: string;
         Posicion_Inicial: string;
         Destino: string;
      }[] = [];

      const _fecha = moment()
         .set("hours", 0)
         .set("minutes", 0)
         .set("seconds", 0);

      this.llamadas.forEach((l) => {
         let tLlamada: Date;
         let hLlamada: Date;
         if (
            l.event.indexOf("COMPLE") > -1 ||
            l.event.indexOf("ANSW") > -1 ||
            l.event.indexOf("FAILED") > -1
         ) {
            tLlamada = _.cloneDeep(_fecha).add(l.data2, "seconds").toDate(); // new Date(Number.parseInt(l.data2) * 1000);
            hLlamada = _.cloneDeep(_fecha).add(l.data1, "seconds").toDate();
         } else if (l.event.indexOf("ABAND") > -1) {
            tLlamada = _.cloneDeep(_fecha).toDate();
            hLlamada = _.cloneDeep(_fecha).add(l.data3, "seconds").toDate();
         } else {
            tLlamada = _.cloneDeep(_fecha).toDate();
            hLlamada = _.cloneDeep(_fecha).toDate();
         }

         toCSV.push({
            Fecha: moment(l.calldate).format("YYYY-MM-DD"),
            Hora: moment(l.calldate).format("HH:mm:ss"),
            Fuente: l.src,
            Cola: l.queuename,
            Agente: l.agent,
            Nombre_Agente: l.agenteNombre,
            Evento: l.event,
            Tiempo_Llamada: moment(tLlamada.getTime()).format("HH:mm:ss"),
            Tiempo_Espera: moment(hLlamada.getTime()).format("HH:mm:ss"),
            Posicion_Inicial:
               l.event.indexOf("COMPLE") > -1 ? l.data3 : l.data2,
            Destino: l.dst,
         });
      });

      if (!!this.http_ && !this.http_.closed) {
         this.http_.unsubscribe();
      }

      this.http_ = this.$http
         .post(this.$header.getAPIurl() + "/csv", toCSV, {
            headers: this.$header.getHeaders(),
         })
         .subscribe(
            (res: { file: string }) => {
               $("#modalWait_kerberus").modal("hide");
               window.open(res.file);
            },
            (err) => console.log(err)
         );
   }
}
